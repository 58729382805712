<template>
  <div class="columns is-multiline" oncontextmenu="return false;">
    <div class="column is-12 content mb-0">
      <h2>Helpful travel insurance information</h2>
    </div>
    <div class="column is-3" v-for="c in cards" :key="c.id">
      
      <router-link class="" :to="{ name: c.link }" v-if="!c.direct">
        <div class="card b-shdw-3">
          <div class="card-image">
            <figure class="image is-4by3v">
              <picture>
                <source :srcset="getImgPath(c.images.webp)" type="image/webp">
                <source :srcset="getImgPath(c.images.jpg)" type="image/jpeg">
                <img :src="getImgPath(c.images.webp)" :alt="c.title" loading="lazy">
              </picture>
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <h4 class="product-title text-center text-c2g-blue">
                {{ c.title }}
              </h4>
            </div>
          </div>
        </div>
      </router-link>

      <router-link class="" :to="c.direct" v-else>
        <div class="card b-shdw-3">
          <div class="card-image">
            <figure class="image is-4by3v">
              <picture>
                <source :srcset="getImgPath(c.images.webp)" type="image/webp">
                <source :srcset="getImgPath(c.images.jpg)" type="image/jpeg">
                <img :src="getImgPath(c.images.webp)" :alt="c.title" loading="lazy">
              </picture>
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <h4 class="product-title text-center text-c2g-blue">
                {{ c.title }}
              </h4>
            </div>
          </div>
        </div>
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoBoxes',
  data() {
    return {
      cards: [
        {
          title: 'TRAVEL INSURANCE FAQs',
          images: {
            'webp': 'whichwaysign.webp',
            'jpg': 'whichwaysign.jpg'
          },
          link: 'faqs',
        },
        {
          title: 'TRAVEL BY DESTINATION',
          images: {
            'webp': 'travel-by-destination.webp',
            'jpg': 'travel-by-destination.jpg'
          },
          link: 'travelByDestination',
        },
        {
          title: 'OVER 65s TRAVEL INSURANCE',
          images: {
            'webp': 'over_65s.webp',
            'jpg': 'over_65s.jpg'
          },
          link: 'over65s',
        },
        {
          title: 'EHIC / GHIC INFORMATION',
          images: {
            'webp': 'ehic_ghic_info.webp',
            'jpg': 'ehic_ghic_info.jpg'
          },
          link: '',
          direct: '/blog/ghic-information-everything-you-need-to-know',
        },
        {
          title: 'CRUISE COVER',
          images: {
            'webp': 'cruise_travel_insurance.webp',
            'jpg': 'cruise_travel_insurance.jpg'
          },
          link: 'cruiseTravelInsurance',
        },
        {
          title: 'FAMILY TRAVEL INSURANCE',
          images: {
            'webp': 'family_travel_insurance.webp',
            'jpg': 'family_travel_insurance.jpg'
          },
          link: 'familyTravelInsurance',
        },
        {
          title: 'WINTER SPORTS TRAVEL INSURANCE',
          images: {
            'webp': 'winter_sports_travel_insurance.webp',
            'jpg': 'winter_sports_travel_insurance.jpg'
          },
          link: 'winterSportsTravelInsurance',
        },
        {
          title: 'DO I REALLY NEED TRAVEL INSURANCE?',
          images: {
            'webp': 'do_need_insurance.webp',
            'jpg': 'do_need_insurance.jpg'
          },
          link: 'needTravelInsurance',
        },
        {
          title: 'HOLIDAY CHECKLIST',
          images: {
            'webp': 'holiday_checklist.webp',
            'jpg': 'holiday_checklist.jpg'
          },
          link: 'holidayChecklist',
        },
        {
          title: 'TRAVEL INSURANCE HELP',
          images: {
            'webp': 'travel-insurance-help.webp',
            'jpg': 'travel-insurance-help.jpg'
          },
          link: 'travelInsuranceHelp',
        },
        {
          title: 'BUSINESS TRAVEL INSURANCE',
          images: {
            'webp': 'business_travel_insurance.webp',
            'jpg': 'business_travel_insurance.jpg'
          },
          link: 'businessTravelInsurance',
        },
        {
          title: 'GROUP TRAVEL INSURANCE',
          images: {
            'webp': 'group_travel_insurance.webp',
            'jpg': 'group_travel_insurance.jpg'
          },
          link: 'groupTravelInsurance',
        },
      ],
    };
  },
  methods: {
    getImgPath(img) {
      return require('../../assets/images/info/' + img);
    }
  }
};
</script>

<style lang="scss" scoped>
.columns {
  margin: 0 0 0 0;
}
.product-title {
  margin-top: 0;
  margin-bottom: 0;
  z-index: 1;
  position: relative;
  line-height: 1.3;
}
.content {
  min-height: 50px;
}

h2 {
  text-align: center;
  color: $c2g_blue;
}

.card {
  border-radius: 8px;
  // Product card image
  .card-image .image img {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    transition: opacity 0.4s;
    cursor: pointer;
    height: 210px;
    // object-fit: cover;
  }
  .card-image .image img:hover {
    opacity: 0.6;
    transition: opacity 0.4s;
  }
  //   .card-content {
  //     p {
  //       min-height: 100px;
  //     }
  //   }
}
</style>
